<template>
  <v-container fluid>
    <v-row>
      <v-col cols="8">
        <v-card>
          <v-card-title primary-title>{{ $t("speakerList") }}</v-card-title>
          <gsl-timer></gsl-timer>
          <v-card-title class="text-subtitle-1">
            {{ $t("currentSpeaker") }}
            <v-spacer></v-spacer>
          </v-card-title>
          <v-card-text>
            <v-list>
              <div v-if="speaker">
                <v-list-item>
                  <v-list-item-avatar>
                    <img :src="getImage(speaker.code)" :alt="speaker.name_en" />
                  </v-list-item-avatar>
                  <v-list-item-content>
                    <v-list-item-title class="justify-center">
                      <span class="text-h6">
                        <span v-if="$root.$i18n.locale === 'zh'">
                          {{ speaker.name_zh }}
                        </span>
                        {{ speaker.name_en }}</span
                      >
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </div>
            </v-list>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="4">
        <v-card>
          <v-card-title primary-title>{{ $t("addSpeaker") }}</v-card-title>
          <v-card-text>
            <div v-if="this.$store.getters.inSessionCountriesEmpty">
              <p class="text-h6">{{ $t("add-countries") }}</p>
            </div>
            <v-text-field
              flat
              outlined
              v-model="search"
              prepend-inner-icon="mdi-magnify"
            ></v-text-field>
            <v-divider></v-divider>
            <v-container style="max-height: 500px" class="overflow-y-auto">
              <div
                v-for="(item, index) in countriesPresentFiltered"
                :key="index"
              >
                <div v-if="inSpeakingList(item)" style="background: #e0e0e0">
                  <v-list-item>
                    <v-list-item-avatar>
                      <img :src="getImage(item.code)" alt="item.name_en" />
                    </v-list-item-avatar>
                    <v-list-item-content>
                      <span v-if="$root.$i18n.locale === 'zh'">
                        {{ item.name_zh }}
                      </span>
                      {{ item.name_en }}</v-list-item-content
                    >
                  </v-list-item>
                </div>
                <div v-else>
                  <v-list-item @click="addToSpeakingList(item)">
                    <v-list-item-avatar>
                      <img :src="getImage(item.code)" alt="item.name_en" />
                    </v-list-item-avatar>
                    <v-list-item-content>
                      <span v-if="$root.$i18n.locale === 'zh'">
                        {{ item.name_zh }}
                      </span>
                      {{ item.name_en }}</v-list-item-content
                    >
                  </v-list-item>
                </div>
              </div>
            </v-container>
            <!-- </v-list-item-group> -->
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import gslTimer from "../components/gslTimer";
export default {
  metaInfo() {
    return {
      title: this.$t("speaker"),
    };
  },
  components: {
    gslTimer: gslTimer,
  },
  data() {
    return {
      search: "",
      countriesPresentFiltered: this.countriesPresent,
      speaker: null,
    };
  },
  watch: {
    search(val) {
      this.filterCountriesPresent(val);
    },
  },
  computed: {
    isEmpty() {
      var len = this.speakingList.length;
      console.log(len);
      return len === 0;
    },
    countriesPresent: {
      cache: false, //每次访问都更新取值，
      get: function () {
        return this.$store.getters.inSessionCountriesPresent;
      },
    },
  },
  methods: {
    filterCountriesPresent(val) {
      const text = val.toLowerCase();
      const arr = this.countriesPresent.filter(
        (x) =>
          x.code.toLowerCase().includes(text) ||
          x.name_zh.toLowerCase().includes(text) ||
          x.name_en.toLowerCase().includes(text)
      );
      this.countriesPresentFiltered = arr;
    },
    getImage(code) {
      if (code.length > 3) {
        return require(`../assets/flags/UN.svg`);
      } else {
        return require(`../assets/flags/${code}.svg`);
      }
    },
    addToSpeakingList(item) {
      this.speaker = item;
    },
    inSpeakingList(item) {
      return this.speaker === item;
    },
  },
  created() {},
};
</script>

<i18n>
{
  "en": {
    "speakerList": "Speaker List",
    "currentSpeaker": "Current Speaker",
    "next": "Next",
    "addSpeaker": "Add Speaker",
    "your-committee": "Your Committee",
    "committee-name": "Committee Name",
    "countries": "Countries",
    "inputOrSearch": "Filter Countries by Name or Code",
    "mod": "Mod",
    "add-countries": "Please Add Countries in Setup or Roll Call",
    "cancel-all": "Clear All",
    "speaker": "Speaker",
    "start": "Start"
  },
  "zh": {
    "speakerList": "发言名单",
    "currentSpeaker": "当前发言",
    "next": "下一位",
    "addSpeaker": "新增发言",
    "your-committee": "你的委员会",
    "committee-name": "委员会名称",
    "inputOrSearch": "输入国家中文名，英文名，或国家代码筛选",
    "countries": "个国家",
    "add-countries": "请在设置中添加国家或点名",
    "cancel-all": "全部取消",
    "speaker": "发言",
    "start": "开始"
  }
}
</i18n>