<template>
  <div>
    <v-card elevation="0">
      <v-card-text>
        <v-card>
          <v-card-text class="text-center">
            <vac :left-time="leftTime" :auto-start="false" ref="vac2">
              <span slot="before">
                <div class="text-h1">
                  {{ timer.min | doubleDigits }}:{{
                    timer.sec | doubleDigits
                  }}
                  / {{ timer.min | doubleDigits }}:{{
                    timer.sec | doubleDigits
                  }}
                </div>
              </span>
              <span slot="process" slot-scope="{ timeObj }">
                <div class="text-h1">
                  {{ timeObj.m }}:{{ timeObj.s }} /
                  {{ timer.min | doubleDigits }}:{{ timer.sec | doubleDigits }}
                </div>
              </span>
              <span slot="finish">
                <div class="text-h1 red--text">
                  <span class="blinking"
                    >00:00/{{ timer.min | doubleDigits }}:{{
                      timer.sec | doubleDigits
                    }}</span
                  >
                </div>
              </span>
            </vac>
          </v-card-text>
        </v-card>

        <v-card-actions class="justify-center">
          <v-row>
            <v-col md="4">
              <v-btn color="secondary" block @click="switchCountdown">
                <v-icon>{{ timerIcon }}</v-icon>
                {{ timerText }}
              </v-btn>
            </v-col>
            <v-col md="4">
              <v-btn color="secondary" block @click="startCountdown">
                <v-icon>mdi-restore</v-icon> {{ $t("restart") }}
              </v-btn>
            </v-col>
            <v-col md="4">
              <v-dialog v-model="dialog" max-width="500px">
                <template v-slot:activator="{ on }">
                  <v-btn color="secondary" block v-on="on" class="ml-2">
                    <v-icon>mdi-timer</v-icon> {{ $t("speakingTime") }}
                  </v-btn>
                </template>
                <v-card>
                  <v-card-title>
                    <span class="headline"> {{ $t("speakingTime") }}</span>
                  </v-card-title>
                  <v-form ref="form" v-model="valid" lazy-validation>
                    <v-card-text>
                      <v-container grid-list-md>
                        <v-layout wrap>
                          <v-flex xs12 sm6>
                            <v-text-field
                              :label="$t('min')"
                              :hint="$t('min')"
                              v-model="timer.min"
                              prepend-icon="mdi-clock"
                              :rules="minRules"
                              outlined
                            ></v-text-field>
                          </v-flex>
                          <v-flex xs12 sm6>
                            <v-text-field
                              :label="$t('sec')"
                              :hint="$t('sec')"
                              v-model="timer.sec"
                              :rules="secondRules"
                              outlined
                            ></v-text-field>
                          </v-flex>
                        </v-layout>
                      </v-container>
                    </v-card-text>
                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <!-- <v-btn color="secondary" @click.native="dialog = false">{{
                        $t("cancel")
                      }}</v-btn> -->
                      <v-btn
                        color="secondary"
                        @click.native="setTimer"
                        :disabled="!valid"
                        >{{ $t("confirm") }}</v-btn
                      >
                    </v-card-actions>
                  </v-form>
                </v-card>
              </v-dialog>
            </v-col>
          </v-row>
        </v-card-actions>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
export default {
  name: "gsl-timer",
  props: ["type"],
  filters: {
    doubleDigits: function (val) {
      const formattedNumber = ("0" + val).slice(-2);
      return formattedNumber;
    },
  },
  data() {
    return {
      dialog: false,
      valid: true,
      timerIcon: "mdi-play",
      timerText: "",
      secondRules: [(v) => (/^\d+$/.test(v) && v <= 60) || this.$t("input60")],
      minRules: [(v) => (/^\d+$/.test(v) && v <= 60) || this.$t("input60")],
      leftTime: 60000,
      timer: {
        min: 1,
        sec: 0,
      },
    };
  },
  methods: {
    startCountdown() {
      console.log("mdi-restart");
      console.log(this.leftTime);
      const vm = this;
      vm.$refs.vac2.startCountdown(true);
    },
    switchCountdown() {
      console.log(this.$refs.vac2.state);
      const vm = this;
      if (
        vm.$refs.vac2.state === "paused" ||
        vm.$refs.vac2.state === "beforeStart"
      ) {
        vm.$refs.vac2.startCountdown();
      } else {
        vm.$refs.vac2.pauseCountdown();
      }
      vm.$nextTick(() => {
        vm.timerIcon =
          vm.$refs.vac2.state === "paused" ? "mdi-play" : "mdi-stop";
        vm.timerText =
          vm.$refs.vac2.state === "paused"
            ? this.$t("continue")
            : this.$t("pause");
      });
    },
    setTimer() {
      // if (this.type === "gsl") {
      const payload = JSON.parse(JSON.stringify(this.timer));
      this.$store.commit("setGslTimer", payload);
      this.leftTime = this.timer.min * 60000 + this.timer.sec * 1000;
      // } else {
      //   this.leftTime = this.timer.min * 60000 + this.timer.sec * 1000;
      // }
      this.dialog = false;
    },
  },
  mounted() {
    this.timerText = this.$t("continue");
    this.timer = JSON.parse(
      JSON.stringify(this.$store.state.currentSession.gslTimer)
    );
    this.leftTime = this.timer.min * 60000 + this.timer.sec * 1000;
    this.$watch(
      () => {
        return this.$refs.vac2.state;
      },
      (val) => {
        if (val === "process") {
          this.timerIcon = "mdi-stop";
          this.timerText = this.$t("pause");
        }
      }
    );
  },
};
</script>

<style scoped>
.blinking {
  animation: blinkingText 1.2s infinite;
}
@keyframes blinkingText {
  0% {
    color: #d32f2f;
  }
  49% {
    color: #d32f2f;
  }
  60% {
    color: transparent;
  }
  99% {
    color: transparent;
  }
  100% {
    color: #d32f2f;
  }
}
</style>

<i18n>
{
  "en": {
    "restart": "Restart",
    "speakingTime": "Speaking Time",
    "min": "Minute",
    "sec": "Second",
    "cancel": "Cancel",
    "confirm": "Confirm",
    "continue": "Continue",
    "input60": "Please Input Integer within 60 ",
    "countries": "Countries",
    "pause": "Pause"

  },
  "zh": {
    "restart": "重新开始",
    "speakingTime": "发言时长",
    "sec": "秒",
    "min": "分钟",
    "cancel": "取消",
    "input60": "输入60以内整数",
    "confirm": "确定",
    "continue": "继续",
    "pause": "暂停"
  }
}
</i18n>